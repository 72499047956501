import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
// import { startLoading, stopLoading } from 'homework-shared';

/**
 * 应用入口
 */
// startLoading();
import(/* webpackChunkName: "app"*/ './main').then((module) => {
  module.default();
  // stopLoading();
});
